import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';
import './loading.scss'

const Loader = () => {
    return (
        <div id="loader" className="Loader">
            <ProgressSpinner
                style={{ width: '70px', height: '70px' }}
                className="position-center opacity-100"
                strokeWidth="3" animationDuration=".5s" />
        </div>
    )
}

export default Loader

