import { KeycloakProvider } from "@react-keycloak/web";
// import store from "app/store";
import EmptyPage from 'components/EmptyPage';
import React, { lazy, Suspense, useEffect } from 'react';
import {
    QueryClient,
    QueryClientProvider,
    useQueryErrorResetBoundary,
} from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";

// import { Provider } from "react-redux";
import { AppProvider } from './context/AppProvider';
import keycloak from './keycloak';
import ScrollToTop from './ScrollToTop';
import AppTranslations from './translations/index';
import PageProgress from 'react-page-progress'
import { BrowserRouter } from 'react-router-dom';

import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from "components/ErrorBoundary/ErrorFallback";

import './wrap-app.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './assets/style/layout.scss';

const LazyApp = lazy(() => import("./App"));

const WrapApp = () => {

    const queryClient = new QueryClient()
    useEffect(() => {
        queryClient.setDefaultOptions({
            queries: {
                staleTime: 15000,
                keepPreviousData: true,
                // cacheTime: 0,
                refetchOnWindowFocus: false,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { reset } = useQueryErrorResetBoundary()
    return (
        <BrowserRouter>

        <ErrorBoundary
            onReset={reset}
            FallbackComponent={ErrorFallback}
        >
            <React.Fragment>

                <Suspense fallback={<EmptyPage />}>
                    {/* <Provider store={store}> */}
                    <KeycloakProvider
                        keycloak={keycloak}>
                        <QueryClientProvider client={queryClient}>
                            <AppTranslations>
                                <AppProvider>
                                    <PageProgress color={"#C1282E"} height={4} className="PageProgress" />
                                    <ScrollToTop>
                                        <LazyApp />
                                    </ScrollToTop>
                                </AppProvider>
                            </AppTranslations>
                            <ReactQueryDevtools initialIsOpen />
                        </QueryClientProvider>
                    </KeycloakProvider>
                    {/* </Provider> */}
                </Suspense>
            </React.Fragment>

        </ErrorBoundary>
            </BrowserRouter>
    )
}

export default WrapApp
