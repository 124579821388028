export const common = {
    //SideBar
    HISTORY_LOG: "Lịch sử hoạt động",
    RESPONSE_MANAGE: "Quản lý phản hồi",
    BUSINESS_MANAGE: "Quản lý kinh doanh",
    CATEGORY: "Danh mục",
    PRODUCT_CATEGORY: "Danh mục sản phẩm",
    PROJECT_CATEGORY: "Quản lý dự án",
    VERSION_CATEGORY: "Danh mục phiên bản",
    PROJECT_APPENDIX: "Quản lý dự án",
    API_GROUP: "Nhóm API",
    API_MANAGE: "Quản lý API",
    PARTNER_MANAGEMENT: "Quản lý đối tác",
    MANAGEMENT:"Quản lý",

    //Profile account
    ACCOUNT_INFO: "Thông tin tài khoản",
    ACT: "Hành động",
    //Log manage
    ADMIN_LOG_MANAGE: "Quản lý lịch sử Admin",
    PORTAL_LOG_MANAGE: "Quản lý lịch sử Portal",

    DESCRIPTION: "Mô tả",
    TYPE_DESCRIPTION: "Nhập mô tả",
    CREATE_TIME: "Ngày tạo",
    CANCEL: "Hủy",
    UPDATE: "Cập nhật",
    SAVE: "Lưu",


    GRANT_PERMISSION: "Cấp quyền",
    TO: "đến",
    SHOW_FROM: "Hiển thị từ",
    SUM: "Tổng",
    RECORD: "bản ghi",
    DRAFT: "Lưu nháp",
    TASK: "Tác vụ",
    COMPLETE: "Hoàn thành",
    EDIT: "Sửa",
    PAUSE: "Tạm dừng",
    REFUSE: "Từ chối",
    CODE: "Mã",
    PROCEDURE: "Quy trình",
    HAS_CHECK: "Có kiểm tra",
    NO_CHECK: "Không kiểm tra",
    NO_RECORD: "Không có bản ghi nào",
    OR: "hoặc",
    LOGIN: "Đăng nhập",
    LOGOUT: "Đăng xuất",

    //Common
    DIGITAL_SIGN: "Ký số",
    CTS: "Chứng thư số",
    SIGNATURE: "Chữ ký",
    ACCOUNT_MANAGE: "Quản lý tài khoản",
    NOTE: "Ghi chú",
    TYPE_NOTE: "Nhập ghi chú",
    USER: "Người dùng",
    USER_EMPTY: "Người dùng không được bỏ trống.",
    POSITION: "Chức danh",
    POSITION_EMPTY: "Chức danh không được bỏ trống.",
    ALL: "Tất cả",
    ACCOUNT: "Tài khoản",
    MANAGER: "Người quản lý",
    NAME: "Tên",
    TYPE_NAME: "Nhập tên",
    UPGRADE: "Nâng cấp",
    TYPE: "Loại hình",
    PERSONAL: "Cá nhân",
    NO_STT: "STT",
    SEARCH: "Tìm kiếm",
    ADD: "Thêm mới",
    DELETE: "Xóa",
    VIEW: "Xem",
    CREATED_BY: "Người tạo",
    CREATED_AT: "Thời gian tạo",
    UPDATED_BY: "Người cập nhật",
    UPDATED_AT: "Thời gian cập nhật",
    ACTION: "Tác vụ",
    STATUS: "Trạng thái",
    CLOSE: "Đóng",
    DOWNLOAD: "Tải xuống",
    CONTRACT: "Hợp đồng",
    CODE_DOCUMENT: "Mã văn bản",
    SUPPORT: "Hỗ trợ",
    IMPORT_EXCEL: "Nhập Excel",
    CONFIRM: "Xác nhận",
    LOCK_REASON: "Lý do khóa",
    NO_RESULT: "Không tìm được kêt quả",
    CONTENT: "Nội dung",
    PAGE_NOT_FOUND: "Xin lỗi, trang không tồn tại",
    HOMEPAGE: "Trang chủ",
    PHONE: "Số điện thoại",
    PERMISSION: "Quyền",
    FULL_NAME: "Họ tên",
    PERSONAL_INFORMATION: "Thông tin cá nhân",
    EMAIL: "Email",

    //Config
    CONFIG_GENERAL_SETTING: "Thiết lập chung",
    CONFIG_CONFIG: "Cấu hình chung",
    CONFIG_CONFIG_HSM: "Cấu hình HSM",
    CONFIG_CONFIG_EMAIL: "Cấu hình email",
    CONFIG_CONFIG_MAIL: "Cấu hình mail",
    CONFIG_CONFIG_AUTO: "Cấu hình tự động",
    CONFIG_ROLE_DEFAULT: "Vai trò mặc định",
    DEFAULT_PASSWORD: "Mật khẩu mặc định ",
    CONFIG_HSM_PROVIDER: "Nhà cung cấp",
    CONFIG_URL_SIGN: "URL ký số",
    CONFIG_DOMAIN_EMAIL_LOGIN: "Domain email login",
    CONFIG_MAX_FILE_SIZE_UPLOAD: "Max file size upload",
    CONFIG_LOGO: "Logo",
    CONFIG_USE_CONFIG_DEFAULT: "Sử dụng cấu hình mặc định",
    CONFIG_HOST: "Host",
    CONFIG_ACCOUNT: "Tài khoản",
    CONFIG_PASSWORD: "Mật khẩu",
    CONFIG_PORT: "Cổng",
    CONFIG_EMAIL_SENT: "Email gửi",
    CONFIG_NAME: "Tên đại diện",
    CONFIG_USE_SSL: "Sử dụng SSL",
    CONFIG_AUTO_DOCUMENT_APPROVE_NOTIFI: "Tự động thông báo nhắc nhở phê duyệt tài liệu",
    CONFIG_TIME_APPROVE_NOTIFI: "Thời gian nhắc nhở phê duyệt(giờ)",
    CONFIG_AUTO_CHANGE_PASSWORD_NOTIFI: "Tự động thông báo đổi mật khẩu",
    CONFIG_TIME_CHANGE_PASSWORD: "Thời gian nhắc nhở đổi mật khẩu(ngày)",
    STATUS_INTEGRATE_DOC: "Trạng thái tiếp nhận tài liệu",
    CONFIG_API_CONNECT: "Cấu hình API kết nối",

    VIEW_DETAIL: "Xem chi tiết",
    DELETE_FILE: "Xóa file",

    CONTINUE: "Tiếp tục",
    LANG: "Ngôn ngữ",
    VERSION: "Phiên bản",
    OUT_OF_DATE: "Quá hạn",
    SAVE_AND_EXIT: "Lưu và thoát",
    COME_BACK: "Quay lại",
    SAVE_POSITION: "Lưu vị trí",
    ORGANIZATION: "Tổ chức",
    HOTLINE: "Tổng đài hỗ trợ",

    //EmailTemplate
    MANAGE_EMAIL_TEMPLATE: "Quản lý mẫu email",
    EMAIL_TEMPLATE_SEARCH_TEXT: "Tìm theo tên, tiêu đề hoặc mã",
    EMAIL_TEMPLATE_CODE: "Mã mẫu email",
    EMAIL_TEMPLATE_NAME: "Tên mẫu email",
    EMAIL_SUBJECT: "Tiêu đề email",
    EMAIL_TEMPLATE_CONTENT: "Nội dung email",
    ACTIVE: "Hoạt động",
    DEACTIVE: "Ngưng hoạt động",
    ACTIVATED: "Kích hoạt",
    DEACTIVATED: "Ngưng kích hoạt",
    ACTIVATE_MAIL_TEMPLATE: "Kích hoạt mẫu email",
    ACTIVATE_MAIL_TEMPLATE_MESSAGE: "Bạn có chắc chắn muốn kích hoạt mẫu email",
    EMAIL_TEMPLATE_DEACTIVATE: "Ngưng kích hoạt mẫu email",
    EMAIL_TEMPLATE_DEACTIVATE_MESSAGE: "Bạn có chắc chắn muốn ngưng kích hoạt mẫu email",
    EMAIL_TEMPLATE_UPDATE: "Cập nhật mẫu email",
    EMAIL_TEMPLATE_TABLE_OF_NOTE: "Table of notes",
    EMAIL_TEMPLATE_PARAM: "Parameter",

    //InputUploadFile
    SELECT_FROM_DEVICE: "Chọn từ thiết bị",
    SELECT_FROM_PERSONALDOC: "Chọn từ tài liệu cá nhân",
    CREATE_FROM_SAMPLE: "Tạo tài liệu từ mẫu",

    //LogEmail
    LOG_EMAIL_MANAGE: "Quản lý lịch sử gửi mail",
    LOG_EMAIL_SEND_TO: "Email người nhận",
    LOG_EMAIL_NAME: "Tên email",
    LOG_EMAIL_CODE_OR_TITLE: "Tìm theo mã, tên hoặc tiêu đề",
    RESEND: "Gửi lại",
    RECEIVER: "Người nhận",
    LOG_EMAIL_TITLE_VIEW: "Xem chi tiết lịch sử mẫu mail",
    LOG_EMAIL_RESEND_MAIL: "Bạn có chắc chắn muốn gửi lại email",
    SEND: "Gửi",

    //Login
    PASSWORD: "Mật khẩu",
    LOGIN_FAIL: "Đăng nhập thất bại",
    ERROR_OCCUR: "Có lỗi xảy ra",

    //MenubarRight
    NOTIFY: "Thông báo",
    MARK_ALL_READ: "Đánh dấu tất cả là đã đọc",
    REMOVE_ALL_NOTI: "Gỡ tất cả thông báo",
    MARK_UNREAD: "Đánh dấu là chưa đọc",
    MARK_READ: "Đánh dấu là đã đọc",
    REMOVE_NOTI: "Gỡ thông báo",
    NO_NOTI: "Bạn không có thông báo nào",
    SEE_MORE: "Xem thêm",
    PASSWORD_CHANGE: "Thay đổi mật khẩu",
    REFRESH: "Làm mới",
    SELECT: "Chọn",

    BACK: "Trở lại",
    SYSTEM: "Hệ thống",
    DASHBOARD: "Bảng điều khiển",

    //RoleManage
    MANAGE_ROLE: "Quản lý vai trò",
    ROLE_SEARCH_INPUT_TEXT: "Nhập tên vai trò",
    ROLE_TABLE_NAME: "Tên vai trò",
    ROLE_TABLE_TYPE: "Hệ thống sử dụng",
    SYSTEM_TYPE: "Loại hệ thống",
    SELECT_SYSTEM: "Chọn loại hệ thống",
    ROLE_TABLE_DISPLAY_NAME: "Tên hiển thị",
    ROLE_TABLE_DELETE: "Xóa vai trò",
    ROLE_TABLE_MESSAGE_DELETE: "Bạn có chắc chắn muốn xóa vai trò",
    NO_END: "không ?",
    ROLE_TABLE_MESSAGE_DELETE_MULTI: "Bạn có chắc chắn muốn xóa danh sách vai trò đã chọn không?",
    ROLE_TABLE_ADD: "Thêm mới vai trò",
    ROLE_TABLE_UPDATE: "Cập nhật vai trò",
    ROLE_TABLE_PERMISSION: "Phân quyền",
    ROLE_PERMISSION: "Quyền hạn",
    ROLE_TABLE_VIEW_DETAIL: "Xem chi tiết vai trò",
    ROLE_TABLE_VIEW_DETAIL_PERMISSION: "Xem chi tiết quyền hạn",

    //UserManage
    MANAGE_USER: "Quản lý người dùng",
    VIEW_USER_INFO: "Xem thông tin người dùng",
    USER_SEARCH_INPUT_TEXT: "Tên người dùng hoặc email",
    ROLE: "Vai trò",
    SELECT_ROLE: "Chọn vai trò",
    ORGANIZATION_TREE: "Cây tổ chức",
    TYPE_USER: "Loại người dùng",
    SELECT_TYPE_USER: "Chọn loại người dùng",
    USAGE_CAPACITY: "Dung lượng sử dụng",
    BELONG_ORGTREE: "Thuộc cây tổ chức",
    LOCK: "Khóa",
    UN_LOCK: "Mở khóa",
    RESET_PASSWORD: "Đặt lại mật khẩu",
    CAPACITY_ADJUSTMENT: "Điều chỉnh dung lượng",
    NOT_EXIST: "Không tồn tại",
    HEADER_RESET_PASSWORD: "Đặt lại mật khẩu mặc định",
    CONFIRM_RESET_PASSWORD: "Bạn có chắc chắn muốn đặt lại mật khẩu người dùng",
    ERROR_FILE_IMPORT_TYPE: "không đúng định dạng",
    ERROR_FILE_IMPORT_NULL: "Bạn chưa chọn file ",
    DELETE_USER: "Xóa người dùng",
    CONFIRM_DELETE_USER: "Bạn có chắc chắn muốn xóa người dùng",
    CONFIRM_DELETE_MULTI_USER: "Bạn có chắc chắn muốn xóa danh sách người dùng đã chọn không?",
    USER_ORGANIZATION_TREE_LIST: "Danh sách cây tổ chức",
    USER_HEADER_ADD_USER: "Thêm mới người dùng",
    USER_HEADER_UPDATE_USER: "Cập nhật người dùng",
    PASSWORD_CONFIRM: "Nhập lại mật khẩu",
    PASSWORD_SUGGEST: "Mật khẩu phải có ít nhất 8 ký tự bao gồm ít nhất một ký tự thường, một ký tự viết hoa và một số",
    UPDATE_STATUS: "Cập nhật trạng thái",
    ARE_YOU_SURE_YOU_WANT: "Bạn có chắc chắn muốn",
    ADJUSTED: "Điều chỉnh",
    ADJUSTMENT_TYPE: "Loại điều chỉnh",
    INCREMENT: "Tăng",
    DECREASE: "Giảm",
    IDENTIFIER_CODE: "CCCD/CMND",
    NAME_USER: "Tên người dùng",
    DEPARTMENT: "Phòng ban",
    PARTNER: "Đối tác",
    SELECT_PARTNER: "Chọn đối tác",

    //Noti
    GET_FAIL: "Lấy danh sách thất bại",
    CREATE_SUCCESS: "Tạo mới thành công",
    CREATE_FAIL: "Tạo mới thất bại",
    UPDATE_SUCCESS: "Cập nhật thành công",
    UPDATE_FAIL: "Cập nhật thất bại",
    UPDATE_PASS_SUCCESS: "Đặt lại mật khẩu thành công",
    UPDATE_PASS_FAIL: "Đặt lại mật khẩu thất bại",
    DELETE_SUCCESS: "Xóa thành công",
    DELETE_FAIL: "Xóa thất bại",
    RESEND_SUCCESS: "Gửi lại thành công",
    RESEND_FAIL: "Gửi lại thất bại",
    SUCCESS: "Thành công",
    FAIL: "Thất bại",
    MESSAGE_ERROR: "Có lỗi xảy ra",
    MESSAGE_ERROR_SEARCH: "Không tìm thấy tài liệu",
    MESSAGE_ERROR_LOAD: "Có lỗi xảy ra, bạn cần tải lại trang web",
    MESSAGE_VALIDATE: "Kiểm tra lại dữ liệu nhập",
    SEND_EMAIL_SUCCESS: "Gửi email thành công",
    SEND_EMAIL_FAIL: "Gửi email thất bại",
    CHECK_SUCCESS: "Kiểm tra thành công",
    CHECK_FAIL: "Kiểm tra thất bại",
    NOTIFY_READ: "Đọc thông báo",
    NOTIFY_DELETE: "Gỡ thông báo",
    FILE_DOES_NOT_EXIST: "File không tồn tại",
    FILE_UPLOAD_SUCCESS: "Tải lên file thành công",
    IS_ACTIVE: "Đang hoạt động",
    LOCKED: "Đã khóa",
    PRODUCTS_MANAGEMENT: "Quản lý sản phẩm",
    PRODUCTS_ADD: "Thêm mới sản phẩm",
    PRODUCTS_UPDATE: "Cập nhật sản phẩm",
    PRODUCT_NAME_INPUT_SEARCH: "Nhập tên sản phẩm",
    PRODUCT_NAME: "Tên sản phẩm",
    PRODUCT_LINK: "Link sản phẩm",
    PRODUCT_LOGO: "Logo sản phẩm",
    PRODUCT_CODE: "Mã sản phẩm",
    PRODUCT_IP: "IP kiểm tra trạng thái sản phẩm",
    //product
    PRODUCT_TABLE_DELETE: "Xóa sản phẩm",
    PRODUCT_TABLE_MESSAGE_DELETE: "Bạn có chắc chắn muốn xóa sản phẩm",
    PRODUCT_TABLE_MESSAGE_DELETE_MULTI: "Bạn có chắc chắn muốn xóa danh sách sản phẩm đã chọn không?",
    PRODUCT: "Sản phẩm",
    PRODUCT_VERSION_LIST: "Danh sách phiên bản sản phẩm",
    VERSION_LIST: "Danh sách phiên bản",
    API_LIST: "Danh sách API",
    PRODUCT_DETAIL_VIEW: "Xem chi tiết sản phẩm",
    GENERAL_INFO: "Thông tin chung",
    ADD_NEW_VER: "Thêm mới phiên bản",
    ADD_VER: "Thêm phiên bản",
    UPDATE_VER: "Cập nhật phiên bản",
    VIEW_VER: "Xem chi tiết phiên bản",
    SORT_BY_TIME: "Sắp xếp",
    OLD_TO_NEW: "Từ cũ đến mới",
    NEW_TO_OLD: "Từ mới đến cũ",
    VER_INFO: "Thông tin phiên bản",
    VER_NAME: "Mã phiên bản",
    VER_TIME: "Thời gian chính thức",
    RELEASE_TIME: "Thời gian phát hành",
    LIST_API_INCLUDE: "Danh sách đã bao gồm các API của phiên bản liền trước đó",
    INFO_LIST_API:"Gồm các API của phiên bản liền trước đó",
    LIST_API:"Danh sách API",
    FILE_SIZE_LIMIT: " file vượt quá dung lượng 2MB!",
    FILE_TYPE_LIMIT: " file không đúng định dạng!",
    DRAG_DROP: "Kéo và thả file",
    CHOOSE_LOGO: "Chọn ảnh",
    LOGO_LIMIT: "Dung lượng tối đa: 2mb, định dạng: JPG,JPEG,GIF,PNG",

    //Partner
    TAX_CODE: "Mã số thuế",
    PARTNER_NAME: "Tên đối tác",
    ADD_NEW_PARTNER: "Thêm mới đối tác",
    UPDATE_PARTNER: "Cập nhật đối tác",
    VIEW_PARTNER: "Xem chi tiết đối tác",
    PARTNER_LIST: "Danh sách đối tác",
    DEPUTY: "Người đại diện",
    WEBSITE: "Website",
    PARTNER_SEARCH: "Mã số thuế hoặc tên đối tác",
    PARTNER_DELETE: "Xóa đối tác",
    NOT: "Không",
    DID_YOU_CHOOSE: "Đã chọn không",
    ADDRESS: "Địa chỉ",
    LEADER_PHONE_NUMBER: "SĐT ban lãnh đạo",
    LEADER_EMAIL: "Email ban lãnh đạo",
    RECEIVE_INFORMATION_EMAIL: 'Email tiếp nhận thông tin',
    FIELDS_OPERATION: 'Lĩnh vực hoạt động',
    BUSINESS_RESPONSIBLE: 'Phụ trách kinh doanh',
    PHONE_TECH: 'SĐT kỹ thuật',
    EMAIL_TECH: 'Email kỹ thuật',
    ADD_PROJECT: 'Thêm dự án',
    PROJECT_INFORMATION: 'Thông tin dự án',
    PARTNER_SOFTWARE: 'Phần mềm đối tác',
    SOFTWARE_TYPE: 'Loại phần mềm',
    PROGRAMMING_LANGUAGE: 'Ngôn ngữ lập trình',
    TARGET_CUSTOMERS: 'Đối tượng khách hàng hướng đến',
    EXPECTED_NUMBER_SUBSCRIBERS: 'Số thuê bao kỳ vọng / tháng',
    INTEGRATION_SOFTWARE: 'Phần mềm cần tích hợp',
    DOMAIN: 'Domain',
    IP: 'IP',
    DEMO_ACCOUNT: 'Tài khoản demo',
    LINK_MAIN: 'Link chính',
    LINK_DEMO: 'Link demo',
    INTEGRATION_GROUP: 'Nhóm tích hợp',
    PARTNER_TYPE: 'Loại hình đối tác',
    MAIN_ACCOUNT: 'Tài khoản chính',
    ISSUES_NOTE: 'Các vấn đề lưu ý',
    INTEGRATED_SUPPORT_WORK: 'Công việc hỗ trợ tích hợp',
    INTEGRATING: 'Đang tích hợp',
    INTEGRATED: 'Đã tích hợp',
    INTEGRATION_PAUSE: 'Tạm dừng tích hợp',
    CANCEL_INTEGRATION: 'Hủy tích hợp',
    DECLINE_INTEGRATION: 'Từ chối tích hợp',
    SELECT_PROJECT: 'Chọn dự án',
    SELECT_PRODUCT: 'Chọn sản phẩm',
    NOT_BE_EMPTY: "không được bỏ trống",
    STEP: "Bước",

    // API header
    API_HEADER_MANAGER: "Quản lý API header",
    ADD_NEW_API_HEADER: "Thêm mới API header",
    UPDATE_API_HEADER: "Cập nhật API header",
    DELETE_API_HEADER: "Xóa API header",
    API_HEADER_LIST: "Danh sách API header",
    API_HEADER: "API header",
    NAME_API_HEADER: "Tên API header",
    TYPE_HEADER: "Loại header",
    SELECT_TYPE_HEADER: " Chọn loại header",
    PARAM: "Tham số",
    PARAM_KEY: "Key tham số",
    PARAM_VALUE: "Giá trị tham số",
    ADD_PARAM: "Thêm tham số",
    REMOVE_PARAM: "Xóa tham số",

    // API group
    API_GROUP_MANAGER: "Quản lý nhóm API",
    API_GROUP_NAME: "Tên nhóm API",
    API_GROUP_NAME_TYPE: "Nhập tên nhóm API",
    API_GROUP_ADD: "Thêm mới nhóm API",
    API_GROUP_UPDATE: "Cập nhật nhóm API",
    API_GROUP_MESSAGE_DELETE: "Bạn có chắc chắn muốn xóa nhóm API",
    API_GROUP_MESSAGE_DELETE_MULTI: "Bạn có chắc chắn muốn xóa danh sách nhóm API đã chọn không?",
    API_GROUP_DELETE: "Xóa nhóm API",

    LOADING: "Đang tải...",

    //api manager
    ADD_NEW_API_MANAGER: "Thêm mới API",
    UPDATE_API_MANAGER: "Cập nhật API",
    COPY_API_MANAGER: "Sao chép API",
    VIEW_API_MANAGER: "Xem chi tiết API",
    COPY: "Sao chép",
    DELETE_API_MANAGER: "Xóa API",
    API_MANAGER_LIST: "Danh sách API",
    API_NAME: "Tên API",
    METHOD: "Phương thức",
    SELECT_METHOD: "Chọn phương thức",
    URL: "Url",
    SELECT_VERSION: "Chọn phiên bản",
    SELECT_CATEGORY: "Chọn danh mục",
    API_MANAGER_MESSAGE_DELETE: "Bạn có chắc chắn muốn xóa API",
    API_MANAGER_MESSAGE_DELETE_MULTI: "Bạn có chắc chắn muốn xóa danh sách API đã chọn không?",
    API_INFO: 'Thông tin chung API',
    API_PARAM: 'Tham số API',
    API_EXAMPLE: 'Ví dụ API',
    API_RESPONSE: 'Kết quả API',
    PATH: "Path",
    CLASSIFICATION: "Phân loại",
    SELECT_CLASSIFICATION: "Chọn phân loại",
    HEADER_API: "Header API",
    SELECT_HEADER_API: "Chọn header API",
    EXAMPLE: 'Ví dụ',
    EXAMPLE_PHP: 'Ví dụ theo PHP',
    EXAMPLE_JAVA: 'Ví dụ theo Java',
    EXAMPLE_DOTNET: 'Ví dụ theo Dotnet',
    API_PARAM_NAME: 'Tên tham số API',
    FIELD: 'Field',
    MAX_LENGTH: 'Chiều dài tối đa',
    TYPE_FIELD: 'Loại field',
    SELECT_TYPE_FIELD: 'Chọn loại field',
    REQUIRED: 'Bắt buộc',
    NEXT: "Tiếp",
    PUBLIC: 'Công khai',
    INTERNAL: 'Nội bộ',
    CONFIDENTIAL: 'Bí mật',
    RESTRICTED: 'Hạn chế',
    STRUCTURE: 'Cấu trúc',
    RESPONSE_STATUS: 'Trạng thái kết quả',
    VALUE: 'Giá trị',
    DEFAULT: "Mặc định",
    OTHER: "Khác",
    TYPE_LANGUAGE: "Loại ngôn ngữ",
    SELECT_PROGRAMMING_LANGUAGE: 'Chọn ngôn ngữ lập trình',
    LINK_EXAMPLE: "Link ví dụ",
    SELECT_API_GROUP: "Chọn nhóm API",
    ADD_RESULT: "Thêm kết quả",
    ADD_EXAMPLE: "Thêm ví dụ",

    // Project management
    PROJECT_MANAGEMENT: "Quản lý dự án",
    PROJECT_NAME: "Tên dự án",
    PROJECT: "Dự án",
    PROJECT_LIST: "Danh sách dự án",
    SOFTWARE_SELECT_INTEGRATED: "Chọn phần mềm tích hợp",
    SOFTWARE_INTEGRATED: "Phần mềm tích hợp",
    ADD_NEW_PROJECT: "Thêm mới dự án",
    UPDATE_PROJECT: "Cập nhật dự án",
    PROJECT_DELETE: "Xóa dự án",
    PARTICIPATION_USER: "Người dùng tham gia",
    SELECT_USER: "Chọn người dùng",

    "ExpandAll":"Mở rộng tất cả",
    "CollapseAll":"Thu gọn tất cả",
}
