export const common = {
    //SideBar
    HISTORY_LOG: "History log",
    RESPONSE_MANAGE: "Response management",
    BUSINESS_MANAGE: "Business management",
    CATEGORY: "Category",
    PRODUCT_CATEGORY: "Product category",
    PROJECT_CATEGORY: "Project category",
    VERSION_CATEGORY: "Version category",
    PROJECT_APPENDIX: "Project appendix",
    API_GROUP: "API group",
    API_MANAGE: "API Management",
    PARTNER_MANAGEMENT: "Partner management",
    MANAGEMENT:"Management",

    // Profile account
    ACCOUNT_INFO: "Account information",
    ACT: "Action",
    //Log manage
    ADMIN_LOG_MANAGE: "Admin log management",
    PORTAL_LOG_MANAGE: "Portal log management",


    DESCRIPTION: "Description",
    TYPE_DESCRIPTION: "Type description",
    CREATE_TIME: "Create time",
    CANCEL: "Cancel",
    UPDATE: "Update",
    SAVE: "Save",

    GRANT_PERMISSION: "Grant permission",
    TO: "to",
    SHOW_FROM: "Show from",
    SUM: "Sum",
    RECORD: "records",
    DRAFT: "Draft",
    TASK: "Task",
    COMPLETE: "Complete",
    EDIT: "Edit",
    PAUSE: "Pause",
    REFUSE: "Refuse",
    CODE: "Code",
    PROCEDURE: "Procedure",
    HAS_CHECK: "Check",
    NO_CHECK: "No check",
    NO_RECORD: "No record",
    OR: "or",
    LOGIN: "Login",
    LOGOUT: "Logout",

    //Common
    DIGITAL_SIGN: "Digital sign",
    CTS: "Certificate",
    SIGNATURE: "Signature",
    ACCOUNT_MANAGE: "Manage account",
    NOTE: "Note",
    TYPE_NOTE: "Type note",
    USER: "User",
    USER_EMPTY: "User can not be empty.",
    POSITION: "Position",
    POSITION_EMPTY: "Position can not be empty.",
    ALL: "All",
    ACCOUNT: "Account",
    MANAGER: "Manager",
    NAME: "Name",
    TYPE_NAME: "Type name",
    UPGRADE: "Upgrade",
    TYPE: "Type",
    PERSONAL: "Personal",
    NO_STT: "No",
    SEARCH: "Search",
    ADD: "Add",
    DELETE: "Delete",
    VIEW: "View",
    CREATED_BY: "Created by",
    CREATED_AT: "Created at",
    UPDATED_BY: "Updated by",
    UPDATED_AT: "Updated at",
    ACTION: "Action",
    STATUS: "Status",
    CLOSE: "Close",
    DOWNLOAD: "Download",
    CONTRACT: "Contract",
    CODE_DOCUMENT: "Document code",
    IMPORT_EXCEL: "Import Excel",
    CONFIRM: "Confirm",
    SUPPORT: "Support",
    LOCK_REASON: "Lock reason",
    NO_RESULT: "No results found",
    CONTENT: "content",
    PAGE_NOT_FOUND: "Sorry, page not found",
    HOMEPAGE: "Homepage",
    PHONE: "Phone",
    PERMISSION: "Permission",
    FULL_NAME: "Full name",
    PERSONAL_INFORMATION: "Personal information",
    EMAIL: "Email",

    //Config
    CONFIG_GENERAL_SETTING: "General settings",
    CONFIG_CONFIG: "General config",
    CONFIG_CONFIG_HSM: "HSM config",
    CONFIG_CONFIG_EMAIL: "Email config",
    CONFIG_CONFIG_MAIL: "Mail config",
    CONFIG_CONFIG_AUTO: "Auto config",
    CONFIG_ROLE_DEFAULT: "Default role",
    DEFAULT_PASSWORD: "Default password",
    CONFIG_HSM_PROVIDER: "Provider",
    CONFIG_URL_SIGN: "Sign url",
    CONFIG_DOMAIN_EMAIL_LOGIN: "Domain email login",
    CONFIG_MAX_FILE_SIZE_UPLOAD: "Max file size upload",
    CONFIG_LOGO: "Logo",
    CONFIG_USE_CONFIG_DEFAULT: "Use default config",
    CONFIG_HOST: "Host",
    CONFIG_ACCOUNT: "Account",
    CONFIG_PASSWORD: "Password",
    CONFIG_PORT: "Port",
    CONFIG_EMAIL_SENT: "Email send by ",
    CONFIG_NAME: "Name",
    CONFIG_USE_SSL: "Use SSL",
    CONFIG_AUTO_DOCUMENT_APPROVE_NOTIFI: "Auto notification reminders to approve document",
    CONFIG_TIME_APPROVE_NOTIFI: "Approve reminder time(hour)",
    CONFIG_AUTO_CHANGE_PASSWORD_NOTIFI: "Auto notification change password",
    CONFIG_TIME_CHANGE_PASSWORD: "Reminder time change password(day)",
    STATUS_INTEGRATE_DOC: "Status of document integrating",
    CONFIG_API_CONNECT: "Config API connect",

    VIEW_DETAIL: "View detail",
    DELETE_FILE: "Delete file",

    CONTINUE: "Continue",
    LANG: "Language",
    VERSION: "Version",
    OUT_OF_DATE: "Out of date",
    SAVE_AND_EXIT: "Save and exit",
    COME_BACK: "back",
    SAVE_POSITION: "Save position",
    ORGANIZATION: "Organization",
    HOTLINE: "Hotline",

    //EmailTemplate
    MANAGE_EMAIL_TEMPLATE: "Email template manage",
    EMAIL_TEMPLATE_SEARCH_TEXT: "Search by name, title or code",
    EMAIL_TEMPLATE_CODE: "Code",
    EMAIL_TEMPLATE_NAME: "Name",
    EMAIL_SUBJECT: "Email subject",
    EMAIL_CONTENT: "Email content",
    ACTIVE: "Active",
    DEACTIVE: "Deactive",
    ACTIVATED: "activated",
    DEACTIVATED: "Deactivated",
    ACTIVATE_MAIL_TEMPLATE: "Active email template",
    ACTIVATE_MAIL_TEMPLATE_MESSAGE: "Are you sure to active email template",
    EMAIL_TEMPLATE_DEACTIVATE: "Deactivate email template",
    EMAIL_TEMPLATE_DEACTIVATE_MESSAGE: "Are you sure to deactivate email template",
    EMAIL_TEMPLATE_UPDATE: "Update email template",
    EMAIL_TEMPLATE_TABLE_OF_NOTE: "Table of notes",
    EMAIL_TEMPLATE_PARAM: "Parameter",

    //InputUploadFile
    SELECT_FROM_DEVICE: "Select from device",
    SELECT_FROM_PERSONALDOC: "Select from personal document",
    CREATE_FROM_SAMPLE: "Create document from sample",

    //LogEmail
    LOG_EMAIL_MANAGE: "Manage history mail",
    LOG_EMAIL_SEND_TO: "recipient's email",
    LOG_EMAIL_NAME: "Email name",
    LOG_EMAIL_CODE_OR_TITLE: "code or title",
    RESEND: "Resend",
    RECEIVER: "Receiver",
    LOG_EMAIL_TITLE_VIEW: "View history mail detail",
    LOG_EMAIL_RESEND_MAIL: "Are you sure to resend mail",
    SEND: "Send",

    //Login
    PASSWORD: "Password",
    LOGIN_FAIL: "Login failed",
    ERROR_OCCUR: "An error has occurred",

    //MenubarRight
    NOTIFY: "Notify",
    MARK_ALL_READ: "Mark all as read",
    REMOVE_ALL_NOTI: "Remove all notify",
    MARK_UNREAD: "Mark as unread",
    MARK_READ: "Mark as read",
    REMOVE_NOTI: "Remove notify",
    NO_NOTI: "You don't have any notify",
    SEE_MORE: "See more",
    PASSWORD_CHANGE: "Change password",
    REFRESH: "Refresh",
    SELECT: "Select",

    BACK: "Back",
    SYSTEM: "System",
    DASHBOARD: "Dashboard",

    //RoleManage
    MANAGE_ROLE: "Manager role",
    ROLE_SEARCH_INPUT_TEXT: "Enter role name",
    ROLE_TABLE_NAME: "Role name",
    ROLE_TABLE_TYPE: "Type system",
    SYSTEM_TYPE: "Type system",
    SELECT_SYSTEM: "Select system",
    ROLE_TABLE_DISPLAY_NAME: "Role display name",
    ROLE_TABLE_DELETE: "Delete role",
    ROLE_TABLE_MESSAGE_DELETE: "Are you sure to delete role",
    NO_END: "?",
    ROLE_TABLE_MESSAGE_DELETE_MULTI: "Are you sure to delete roles?",
    ROLE_TABLE_ADD: "Add new role",
    ROLE_TABLE_UPDATE: "Update role",
    ROLE_TABLE_PERMISSION: "Permission",
    ROLE_TABLE_VIEW_DETAIL: "View detail role",
    ROLE_PERMISSION: "Permission",
    ROLE_TABLE_VIEW_DETAIL_PERMISSION: "View detail permission",

    //UserManage
    MANAGE_USER: "Manage user",
    VIEW_USER_INFO: "View user information",
    USER_SEARCH_INPUT_TEXT: "Full name or email",
    ROLE: "Role",
    SELECT_ROLE: "Select role",
    ORGANIZATION_TREE: "Organization tree",
    TYPE_USER: "User type",
    SELECT_TYPE_USER: "Select user type",
    USAGE_CAPACITY: "Usage capacity",
    BELONG_ORGTREE: "Belong to organization tree",
    LOCK: "Lock",
    UN_LOCK: "Unlock",
    RESET_PASSWORD: "Reset password",
    CAPACITY_ADJUSTMENT: "Capacity adjustment",
    NOT_EXIST: "Does not exist",
    HEADER_RESET_PASSWORD: "Reset default password",
    CONFIRM_RESET_PASSWORD: "Are you sure to reset user password",
    ERROR_FILE_IMPORT_TYPE: "is not in the correct format",
    ERROR_FILE_IMPORT_NULL: "You have not selected the file",
    DELETE_USER: "Delete user",
    CONFIRM_DELETE_USER: "Are you sure to delete user",
    CONFIRM_DELETE_MULTI_USER: "Are you sure to delete selected user list",
    USER_ORGANIZATION_TREE_LIST: "Organization tree list",
    USER_HEADER_ADD_USER: "Add new user",
    USER_HEADER_UPDATE_USER: "Update user",
    PASSWORD_CONFIRM: "Confirm password",
    PASSWORD_SUGGEST: "Password have to at least 8 characters include at least one lowercase letter one uppercase letter and a number",
    UPDATE_STATUS: "Update status",
    ARE_YOU_SURE_YOU_WANT: "Are you sure you want",
    ADJUSTED: "Adjusted",
    ADJUSTMENT_TYPE: "Adjustment type",
    INCREMENT: "Increment",
    DECREASE: "Decrease",
    IDENTIFIER_CODE: "Identify card",
    NAME_USER: "User name",
    DEPARTMENT: "Department",
    PARTNER: "Partner",
    SELECT_PARTNER: "Select partner",

    //Noti
    GET_FAIL: "Get list failed",
    CREATE_SUCCESS: "Create success",
    CREATE_FAIL: "Create failed",
    UPDATE_SUCCESS: "Update success",
    UPDATE_FAIL: "Update failed",
    UPDATE_PASS_SUCCESS: "Reset password success",
    UPDATE_PASS_FAIL: "Reset password failed",
    DELETE_SUCCESS: "Delete success",
    DELETE_FAIL: "Delete failed",
    RESEND_SUCCESS: "Resend success",
    RESEND_FAIL: "Resend failed",
    SUCCESS: "Success",
    FAIL: "Failed",
    MESSAGE_ERROR: "Error occurs",
    MESSAGE_ERROR_SEARCH: "Document not found",
    MESSAGE_ERROR_LOAD: "Error occurs, refresh page",
    MESSAGE_VALIDATE: "Check data input",
    SEND_EMAIL_SUCCESS: "Send mail success",
    SEND_EMAIL_FAIL: "Send mail failed",
    CHECK_SUCCESS: "Check success",
    CHECK_FAIL: "Check failed",
    NOTIFY_READ: "Read notify",
    NOTIFY_DELETE: "Remove notify",
    FILE_DOES_NOT_EXIST: "File not found",
    FILE_UPLOAD_SUCCESS: "Upload success",
    IS_ACTIVE: "Is active",
    LOCKED: "Locked",
    PRODUCTS_MANAGEMENT: "Products management",
    PRODUCTS_ADD: "Add product",
    PRODUCTS_UPDATE: "Update product",
    PRODUCT_NAME_INPUT_SEARCH: "Enter product name",
    PRODUCT_NAME: "Product name",
    PRODUCT_LINK: "Product link",
    PRODUCT_LOGO: "Product logo",
    PRODUCT_CODE: "Product code",
    PRODUCT_IP: "IP for checking status product",
    //product
    PRODUCT_TABLE_DELETE: "Delete product",
    PRODUCT_TABLE_MESSAGE_DELETE: "Are you sure to delete product",
    PRODUCT_TABLE_MESSAGE_DELETE_MULTI: "Are you sure to delete products?",
    PRODUCT: "Product",
    PRODUCT_VERSION_LIST: "List product version",
    VERSION_LIST: "List version",
    API_LIST: "List API",
    PRODUCT_DETAIL_VIEW: "View product detail",
    GENERAL_INFO: "General information",
    ADD_NEW_VER: "Add new version",
    ADD_VER: "Add version",
    UPDATE_VER: "Update version",
    VIEW_VER: "View version detail",
    SORT_BY_TIME: "Sort by time",
    OLD_TO_NEW: "From old to new",
    NEW_TO_OLD: "From new to old",
    VER_INFO: "Version information",
    VER_NAME: "Version name",
    VER_TIME: "Official time",
    RELEASE_TIME: "Release time",
    LIST_API_INCLUDE: "Previous version's APIS included",
    INFO_LIST_API:"Previous APIS included",
    LIST_API:"API list",
    FILE_SIZE_LIMIT: " file size is too big",
    FILE_TYPE_LIMIT: " is not supported file extension",
    DRAG_DROP: "Drag and Drop Image Here",
    CHOOSE_LOGO: "Choose image",
    LOGO_LIMIT: "Max file size: 2mb, accepted: jpg|jpeg|gif|png",

    //Partner
    TAX_CODE: "Tax code",
    PARTNER_NAME: "Partner name",
    ADD_NEW_PARTNER: "Add new partner",
    UPDATE_PARTNER: "Update partner",
    VIEW_PARTNER: "View partner",
    PARTNER_LIST: "Partner list",
    DEPUTY: "Deputy",
    WEBSITE: "Website",
    PARTNER_SEARCH: "Tax code or partner name",
    PARTNER_DELETE: "Delete partner",
    NOT: "Not",
    DID_YOU_CHOOSE: "Did you choose",
    ADDRESS: "Address",
    LEADER_PHONE_NUMBER: "Leader phone number",
    LEADER_EMAIL: "Leader email",
    RECEIVE_INFORMATION_EMAIL: 'Receive information email',
    FIELDS_OPERATION: 'Field operation',
    BUSINESS_RESPONSIBLE: 'Business responsible',
    PHONE_TECH: 'Technical phone number',
    EMAIL_TECH: 'Technical email',
    ADD_PROJECT: 'Add project',
    PROJECT_INFORMATION: 'Project information',
    PARTNER_SOFTWARE: 'Partner software',
    SOFTWARE_TYPE: 'Software type',
    PROGRAMMING_LANGUAGE: 'Programing language',
    TARGET_CUSTOMERS: 'Target customers',
    EXPECTED_NUMBER_SUBSCRIBERS: 'Expected number of subscribers / month',
    INTEGRATION_SOFTWARE: 'Integration software',
    DOMAIN: 'Domain',
    IP: 'IP',
    DEMO_ACCOUNT: 'Demo account',
    LINK_MAIN: 'Link main',
    LINK_DEMO: 'Link demo',
    INTEGRATION_GROUP: 'Integration group',
    PARTNER_TYPE: 'Partner type',
    MAIN_ACCOUNT: 'Main account',
    ISSUES_NOTE: 'Issues note',
    INTEGRATED_SUPPORT_WORK: 'Integrated support work',
    INTEGRATING: 'Integrating',
    INTEGRATED: 'Integrated',
    INTEGRATION_PAUSE: 'Integration pause',
    CANCEL_INTEGRATION: 'Cancel integration',
    DECLINE_INTEGRATION: 'Decline integration',
    SELECT_PROJECT: 'Select project',
    SELECT_PRODUCT: 'Select product',
    NOT_BE_EMPTY: "not be empty",
    STEP: "Step",

    // API header
    API_HEADER_MANAGER: "API header management",
    ADD_NEW_API_HEADER: "Add new API header",
    UPDATE_API_HEADER: "Update API header",
    DELETE_API_HEADER: "Delete API header",
    API_HEADER_LIST: "List API header",
    API_HEADER: "API header",
    NAME_API_HEADER: "Name API header",
    TYPE_HEADER: "Type header",
    SELECT_TYPE_HEADER: " Select type header",
    PARAM: "parameters",
    PARAM_KEY: "Parameters key",
    PARAM_VALUE: "Parameters value",
    ADD_PARAM: "Add parameters",
    REMOVE_PARAM: "Remove parameters",

    // API group
    API_GROUP_MANAGER: "API group management",
    API_GROUP_NAME: "API group name",
    API_GROUP_NAME_TYPE: "Type name API group",
    API_GROUP_ADD: "Add API group",
    API_GROUP_UPDATE: "Update API group",
    API_GROUP_MESSAGE_DELETE: "Are you sure to delete API group",
    API_GROUP_MESSAGE_DELETE_MULTI: "Are you sure to delete API groups?",
    API_GROUP_DELETE: "Delete API group",

    LOADING: "Loading...",

    //api manager
    ADD_NEW_API_MANAGER: "Add API",
    UPDATE_API_MANAGER: "Update API",
    COPY_API_MANAGER: "Copy API",
    VIEW_API_MANAGER: "View API",
    COPY: "Copy",
    DELETE_API_MANAGER: "Delete API",
    API_MANAGER_LIST: "List API",
    API_NAME: "API name",
    METHOD: "Method",
    SELECT_METHOD: "Select method",
    URL: "Url",
    SELECT_VERSION: "Select version",
    SELECT_CATEGORY: "Select category",
    API_MANAGER_MESSAGE_DELETE: "Are you sure to delete API",
    API_MANAGER_MESSAGE_DELETE_MULTI: "Are you sure to delete the selected API list?",
    API_INFO: 'API info',
    API_PARAM: 'API parameter',
    API_EXAMPLE: 'API example',
    API_RESPONSE: 'API response',
    PATH: "Path",
    CLASSIFICATION: "Classification",
    SELECT_CLASSIFICATION: "Select classification",
    HEADER_API: "Header API",
    SELECT_HEADER_API: "Select header API",
    EXAMPLE: 'Example',
    EXAMPLE_PHP: 'Example PHP',
    EXAMPLE_JAVA: 'Example Java',
    EXAMPLE_DOTNET: 'Example Dotnet',
    API_PARAM_NAME: 'API param name',
    FIELD: 'Field',
    MAX_LENGTH: 'Max length',
    TYPE_FIELD: 'Type field',
    SELECT_TYPE_FIELD: 'Select type field',
    REQUIRED: 'Required',
    NEXT: "Next",
    PUBLIC: 'Public',
    INTERNAL: 'Internal',
    CONFIDENTIAL: 'Confidential',
    RESTRICTED: 'Restricted',
    STRUCTURE: 'Structure',
    RESPONSE_STATUS: 'Response status',
    VALUE: 'Value',
    DEFAULT: "Default",
    OTHER: "Other",
    TYPE_LANGUAGE: "Language type",
    SELECT_PROGRAMMING_LANGUAGE: 'Select programming language',
    LINK_EXAMPLE: "Link example",
    SELECT_API_GROUP: "Select API group",
    ADD_RESULT: "Add result",
    ADD_EXAMPLE: "Add example",
    // Project management
    PROJECT_MANAGEMENT: "Project management",
    PROJECT_NAME: "Project name",
    PROJECT: "Project",
    PROJECT_LIST: "List projects",
    SOFTWARE_SELECT_INTEGRATED: "Select integrated software",
    SOFTWARE_INTEGRATED: "Integrated software",
    ADD_NEW_PROJECT: "Add new project",
    UPDATE_PROJECT: "Update project",
    PROJECT_DELETE: "Delete project",
    PARTICIPATION_USER: "Participation user",
    SELECT_USER: "Select user",

    "ExpandAll":"Expand All",
    "CollapseAll":"Collapse All",
};
